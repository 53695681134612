.headerContainer{
    background-color: #f9f9f9;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
    position: fixed;
    top: 0;
    left: 0;
    height: 85px;
    width: 100%;
    transition: all 0.5s;
    padding: 10px;;
    display: flex;
    z-index: 20;
}
.headerLogo{
    width: 50%;
}

.headerInfo{
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
    color: #000;
    font-size: large;
}

.headerLogo{
    display: flex;
    align-items: center;
}

.LogoMobile{
    display: none;
}

.LogoDesk{
    width: 250px;
    height: 37px;
    margin-left: 30px;
}

.menuMobile{
    display: none;
}

.userPic{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
}

.contentUserPic{
    background-color: #63b745;
    color: #fff;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 30px;
}

.footer{
    bottom: 0;
    display: flex;
    left: 0;
    padding: 15px 0;
    position: relative;
    right: 0;
    z-index: 9;
  }
  
  .logowhite{
    max-width: 250px;
    width: 100%;
  }

  .bg-purple{
    background-color: #752367;
  }

  .text-white{
    color: #fff;
  }

@media (max-width: 1200px) {
    .LogoMobile{
        display: block;
        width: 75px;
        margin-left: 10px;
    }
    
    .LogoDesk{
        display: none;
    }
    
    .menuMobile{
        display: block;
    }
}