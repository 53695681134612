@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

:root {
    --primary: #eeeeee;
    --secondary: #752367;
    /* --grey: #aaaaaa; */
    --grey: #808080;
    --white: #ffffff;
    --black: #222222;
    --new: #dce6f0;
  }

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body, input{
    font-family: 'Roboto', sans-serif;
}

.container{
    position: relative;
    width: 100%;
    max-width: 100% !important;
    min-height: 100vh;
    background-color: #fff;
    background-image: url('../img/bg-login.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom;
    overflow: hidden;
}

.container:before{
    content: '';
    position: absolute;
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
    background: linear-gradient(-45deg, #f3f3f3, #ffffff);
    top: -30%;
    right: 50%;
    transform: translateY(-50%);
    z-index: 6;
    transition: 1.2s ease-in-out;
    display: none;
}

.forms-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: all;
}

.signin-signup{
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 5;
    transition: 1s 0.7s ease-in-out;
}

form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 5rem;
    overflow: hidden;
    transition: 0.2s 0.7s ease-in-out;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
    background-color: #fbfbfb;
}

form.general-info-form{
    border: none;
    box-shadow: none;
    background-color: var(--white);
}

form.sign-in-form{
    z-index: 20;
}

form.sign-up-form{
    z-index: 1;
    opacity: 0;
}

.title{
    font-size: 2.2rem;
    color: #752367;
    margin-bottom: 20px;
    font-weight: 600;
}

.title-form{
    margin-bottom: 30px;
}

.subtitle{
    font-size: 1.2rem;
}

.input-field{
    min-width: 300px;
    width: 100%;
    height: 50px;
    background-color: #fcfcfc;
    margin: 10px 0;
    display: grid;
    grid-template-columns: 100%;
    padding: .4rem 1rem;
    border: 1px solid;
}

.input-field input{
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
}

.input-field input::placeholder{
    color: #aaa;
    font-weight: 500;
}

.btn-wetrack{
    width: 150px;
    height: 49px;
    border: none !important;
    outline: none;
    border-radius: 10px !important;
    cursor: pointer !important;
    background-color: #752367 !important;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    margin: 10px 0;
    transition: .5s !important;
}

.btn-wetrack:hover{
    background-color: #bf6ab1;
}

.panels-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    pointer-events: all;
}

.panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
    text-align: center;
    z-index: 7;
}

.left-panel{
    pointer-events: all;
    padding: 3rem 17% 2rem 12%;
}

.right-panel{
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
}

.panel .content{
    color: #fff;
    transition: .9s .6s ease-in-out;
}

.panel h3{
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}

.panel p{
    font-size: 0.95rem;
    padding: 0.7rem 0;
}

.btn-wetrack.trasparent{
    margin: 0;
    background: none;
    border: 2px solid #fff;
    width: 130px;
    height: 41px;
    font-weight: 600;
    font-size: 0.8rem;
}

.text-aqui{
    color: #752367;
    font-weight: 600;
    cursor: pointer;
}

.image{
    width: 400px;
    transition: 1.1s .4s ease-in-out;
    margin-bottom: 20px;
}

.image-login{
    width: 400px;
    transition: 1.1s .4s ease-in-out;
    margin-bottom: 20px;
}

/* .right-panel .content, .right-panel .image{
    transform: translateX(800px);
} */

/* ANIMATION */
.container.sign-up-mode:before{
    transform: translate(100%, -50%);
    right: 52%;
}

/* .container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content{
    transform: translateX(-800px);
} */

.container.sign-up-mode .right-panel .content,
.container.sign-up-mode .right-panel .image{
    transform: translateX(0px);
}

/* .container.sign-up-mode .left-panel{
    pointer-events: none;
} */

.container.sign-up-mode .right-panel{
    pointer-events: all;
}

.container.sign-up-mode .signin-signup{
    left: 25%;
}

.container {
    max-width: 100%;
}

.container.sign-up-mode form.sign-in-form{
    z-index: 1;
    opacity: 0;
}

.container.sign-up-mode form.sign-up-form{
    z-index: 2;
    opacity: 1;
}

.th-report{
    text-align: center;
}

/* Salidas */

.title-content {
    display: flex;
    align-items: center;
    padding: 2rem 5rem;
}

.title-container{
    width: 50%;
    display: flex;
    justify-content: start;
}

.title-body{
    color: #752367;
    font-weight: 400;
    margin-left: 10px;
    font-size: 2.5rem;
    align-items: center;
}

.button-container{
    display: flex;
    width: 50%;
    justify-content: end;
}

.button-container .btn-outbound{
    width: 200px;
    height: 36px;
    border: 1px solid var(--secondary);
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    color: #752367;
    text-transform: uppercase;
    font-weight: 500;
    margin: 10px 0;
    transition: .5s;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 1rem;
}

.button-container .btn-outbound:hover{
    background-color: #bf6ab1;
}

.button-container .btn-menu-wizard {
    width: 120px;
    height: 36px;
    border: 1px solid var(--secondary);
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    color: #752367;
    text-transform: uppercase;
    font-weight: 500;
    margin: 10px 0;
    transition: .5s;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: 10px;
}

.button-container .btn-menu-wizard:hover{
    background-color: #bf6ab1;
}

.button-container .btn-menu-wizard-step {
    width: 120px;
    height: 36px;
    border: 1px solid var(--secondary);
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: #752367;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    margin: 10px 0;
    transition: .5s;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: 10px;
}

.button-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-container{
    margin: 40px 20px;
    overflow-y: scroll;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
    padding: 30px;
}

.table-container-dashboard{
    margin: 40px 20px;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
    padding: 30px;
    border-radius: 20px;
}

.separator{
    background-color: #752367;
    width: 100%;
    height: 2px;
}

.sin-incidencias{
    background-color: #a4f7a4;
    border-radius: 20px;
    padding: 1px;
    display: flex;
    align-items: center;
    color: green;
    font-size: .8rem;
    width: 100%;
}

.sin-incidencias .circle-color{
    width: 12px;
    height: 12px;
    background-color: green;
    border-radius: 50px;
    margin: 3px;
}

.con-incidencias{
    background-color: #fff087;
    border-radius: 20px;
    padding: 1px;
    display: flex;
    align-items: center;
    color: #000;
    font-size: .8rem;
    width: 100%;
}

.con-incidencias .circle-color{
    width: 12px;
    height: 12px;
    background-color: #f5b643;
    border-radius: 50px;
    margin: 3px;
}

.enviado{
    background-color: #a4f7a4;
    border-radius: 20px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
    font-size: .8rem;
}

.icon-doc svg > path{
    fill: #752367;
}

.icon-doc{
    cursor: pointer;
} 

.icon-resend svg > path{
    fill: var(--white);
}

.icon-resend{
    cursor: pointer;
} 

table thead tr{
    border-bottom: 1px solid #752367;
}

.table-dashboard thead tr{
    border-bottom: 1px solid #752367;
    border-top: 1px solid #752367;
}

.badge-enviado{
    background-color: #a4f7a4 !important;
    color: green;
}

.dashboard-icon i > svg{
    width: 30px;
    height: 30px;
}

/* Progress bar */

.progress-container {
    position: relative;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    width: 80%;
  }
  
.progress-container::after {
    content: "";
    position: absolute;
    height: 9px;
    width: 92%;
    top: 25%;
    left: 3rem;
    background-color: var(--grey);
    z-index: 0;
}

.progressBar {
    position: absolute;
    left: 3rem;
    height: 3px;
    top: 33%;
    width: 0;
    transform: translateY(-50%);
    background-color: var(--secondary);
    transition: width 0.2s;
    z-index: 1;
}

.progress-step {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
    color: var(--black);
    z-index: 2;
}

.progress-step .fa-solid {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey);
    height: 50px;
    width: 50px;
    font-size: 1.3rem;
    border-radius: 50%;
    background-color: var(--white);
    border: 3px solid var(--grey);
    margin-bottom: 10px;
    transition: border 0.2s, color 0.2s;
}

.btn-container {
    margin-top: 2rem;
    display: flex;
}

.btn-container .btnStep {
    padding: 0.5rem 1.5rem;
    margin-left: 1rem;
    border-radius: 10px;
    cursor: pointer;
    color: var(--white);
    font-weight: 500;
    background-color: var(--secondary);
    border: none;
    font-size: .8rem;
    width: 120px;
    height: 36px;
}

.btnStep{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .btn-container .btnStep:hover {
    border: 1px solid var(--secondary);
    background-color: var(--white);
    color: var(--secondary);
} */

.btn-container .btnDraft {
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
    border-radius: 10px;
    cursor: pointer;
    color: var(--secondary);
    font-weight: 500;
    background-color: var(--white);
    border: 1px solid var(--secondary);
    transition: .5s;
    font-size: .8rem;
    width: 120px;
    height: 36px;
}

.btn-container .btnDraft:hover {
    background-color: #e2bddb;
}

.progress-step .fa-solid.activeStep {
    border: 3px solid var(--secondary);
    color: var(--secondary);
    background-color: var(--secondary);
}

.activeStep svg > path{
    fill: var(--white);
}

.btn.disabled {
    background-color: var(--grey);
    color: var(--white);
    cursor: not-allowed;
}

.resendReport{
    background-color: var(--secondary);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {  
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .paginate-t li{
    cursor: pointer;
    padding: 10px;
  }
  
  .paginate-t li.disabled{
    color: #ced4da;
    cursor:default;
  }
  
  .paginate-t li:nth-last-of-type(3){
    cursor: default;
    font-size: 12px;
    font-weight: 500;
  }

  ul{
  list-style: none;
}

.table th .filter-table{
    align-items: center;
    display: flex;
    background-color: #fff;
    border: none;
    color: #79787b;
    padding: 0;
  }

  ul.list-filter{
    margin: 0;
    padding-left: 1rem;
  }

  .list-filter li{
    cursor: pointer;
    padding: 10px 15px;
    font-weight: 400;
  }
  
  .list-filter label{
    font-weight: 400;
    padding-left: 10px;
    padding-right: 15px;
    white-space: nowrap;
  }
  
  .list-filter li.active-tsh{
    background-color: #63B745;
    color: #ffffff;
  }
  
  .list-filter .form-check-input{
    margin-top: 0;
  }
  
  .list-filter .form-check {
    padding-bottom: 5px;
    padding-left: 2.5em;
    padding-top: 5px;
  }
  
  .radio-custom.list-filter label{
    font-weight: 400;
    padding-left: 14px;
    padding-right: 14px;
    white-space: nowrap;
  }

  input.form-check-input{
    border: 3px solid;
    background-color: var(--white);
    border-color: #656565;
  }

  .form-check-input:checked {
    border: 1px solid;
    background-color: #63B745 !important;
    border-color: #656565 !important;
}

  /* Content wizard */

.title-wizard{
    display: flex;
    justify-content: center;
    margin: 40px 0 20px;
    font-size: 25px;
    color: var(--secondary);
    font-weight: 400;
}

.body-content{
    margin-top: 30px;
}

.general-info-form{
    font-size: 14px;
    font-weight: 400;
}

.wizard-container{
    display: flex;
    justify-content: center;
    padding: 3rem 0;
}

.wizard-input{
    border: 1px solid #000 !important;
    padding: 10px !important;
    border-radius: unset !important;
}

.wizard-icon{
    display: flex;
    margin-right: 3px;
}

.wizard-icon svg > path{
    fill: var(--secondary);
}

.wizard-icon-step{
    display: flex;
}

.wizard-icon-step svg > path{
    fill: var(--white);
}


/* Button Input */

.buttonInput{
    justify-content: center;
    display: flex;
    border: 1px solid #000;
    padding: 8px;
    background-color: #752367;
    color: #fff;
    cursor: pointer;
}

.labelFiles{
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnDraft{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Images */

.square {
    position: relative;
}
  
.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    float: left;
}

.content_img {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid white;
    display: flex;
    border-radius: 20px;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
}

.img-responsive {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.image-container{
    padding: 20px;
    border-radius: 20px;
    border: 2px dashed #e2bddb;
}

.close-image{
    left: 10px;
    top: 10px;
}
/* Email tags */

.react-tagsinput--focused{
    outline: none;
    border-color: #000000 !important;
}

.react-tagsinput {
    border: 1px solid #000000 !important;
    padding: 4px 5px 2.5px !important;
}

.react-tagsinput-input {
    width: 160px !important;
}

.react-tagsinput span {
    display: flex;
    flex-wrap: wrap;
}

.custom-tag{
    border-radius: 20px;
    background-color: #ffe0f7;
    padding: 2px 5px;
    border: 1px solid #752367;
    height: 25px;
    margin: 2px 2px 0 0;
}

/* Firma */

.signatureContent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsSignature{
    width: 500px;
    height: 200px;
    position: absolute;
    display: flex;
    justify-content: end;
}

.buttonsSignature button{
    position: relative;
    z-index: 4;
    height: 25px;
    width: 140px;
    top: -26px;
    border: none;
    margin-right: 5px;
    border-radius: 20px 20px 0px 0px;
}

.sigCanvas{
    border: 2px dashed #e2bddb;
    z-index: 3;
}

/* Dashboard */

.dashboardCard{
    margin: 20px 10px;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
    border-radius: 10px;
    padding: 10px 20px;
    width: 400px;
}

.iconDashboard{
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconDashboard i > svg{
    width: 80px;
    height: 80px;
}

/* .iconDashboard i svg > path{
    fill: var(--secondary);
} */

.contentCardDashboard{
    display: flex;
    justify-content: center;
    align-items: center;
}

.textCard{
    text-align: center;
}

.numberCard{
    font-size: 3em;
    font-weight: 400;
}

.titleCard{
    font-size: 1em;
}

.iconCard{
    display: flex;
    align-items: end;
    justify-content: center;
}

.totalReportsCharts{
    margin: 20px 10px !important;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
    padding: 30px !important;
    border-radius: 10px;
    height: 93%;
}

.reportsCharts{
    margin: 20px 10px !important;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
    padding: 30px !important;
    border-radius: 10px;
    height: 90%;
}

/* Responsive */

@media (max-width: 1370px){
    .progress-container::after {
        width: 90%;
    }
}

@media (max-width: 1350px){
    .progress-container::after {
        width: 85%;
    }
}

@media (max-width: 1200px){
    .container{
        background-position: right;
    }

}
.table-container > table{
    font-size: 12px;
}


@media (max-width: 870px){
    .container{
        min-height: 800px;
        height: 100vh;
    }

    .container:before {
        width: 1500px;
        height: 1500px;
        left: 35%;
        bottom: 80%;
        transform: translateX(-50%);
        right: initial;
        top: initial;
        transition: 2s ease-in-out;
    }

    .signin-signup{
        width: 100%;
        /* left: 50%;
        top: 60%;
        transform: translate(-50%, -100%); */
        transition: 1s 0.8s ease-in-out;
    }

    .panels-container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
    }

    .panel{
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 2.5rem 8%;
    }

    .panel h3{
        font-size: 1.2rem;
    }

    .panel p{
        font-size: 0.7rem;
        padding: 0.5rem 0;
    }

    .btn-wetrack.trasparent{
        width: 110px;
        height: 35px;
        font-size: 0.7rem;
    }

    .image{
        width: 100%;
    }

    .image-login{
        width: 340px;
        transition: 0.9s 0.6s ease-in-out;
    }

    .left-panel{
        grid-row: 1 / 2;
    }

    .right-panel{
        grid-row: 3 / 4;
    }

    .right-panel .content, .right-panel .image{
        transform: translateY(300px);
    }

    .container.sign-up-mode:before{
        transform: translate(-50%, 100%);
        bottom: 196%;
        right: initial;
    }

    /* .container.sign-up-mode .left-panel .image,
    .container.sign-up-mode .left-panel .content{
        transform: translateY(-300px);
    } */

    /* .container.sign-up-mode .signin-signup{
        top: 32%;
        left: 50%;
        transform: translate(-50%, 0);
    } */

    .btn-outbound{
        width: 115px;
    }

    .button-content{
        font-size: 14px;
    }
}

@media (max-width: 769px){
    .title-container{
        justify-content: center;
    }

    .button-container{
        justify-content: center;
    }
}

@media (max-width: 570px) {
    form{
        padding: 2rem 1.5rem;
    }

    .image{
        width: 100%;
    }

    .image-login{
        width: 100%;
    }

    .panel .content{
        padding: 0.5rem 1rem;
    }

    .container:before {
        bottom: 86%;
        left: 50%;
    }

    .container.sign-up-mode:before {
        transform: translate(-50%, 100%);
        bottom: 260%;
        right: initial;
    }

    /* .signin-signup {
        width: 100%;
        left: 50%;
        top: 76%;
        transform: translate(-50%, -100%);
        transition: 1s 0.8s ease-in-out;
    } */

    .progress-container::after {
        width: 80%;
    }

    .progress-step{
        font-size: 9px;
    }

    /* .container.sign-up-mode:before{
        bottom: 25%;
        left: 50%;
    }

    .container.sign-up-mode .signin-signup {
        top: 15%;
    } */

    .headerInfo span{
        display: none;
    }
}