.container-nav{
    display:flex;
    max-width: 100% !important;
    padding: 0 !important;
}

main{
    width: 100%;
    min-height: 100vh;
    background: #ebebeb;
}

main .bodyContainer{
    margin: 95px 10px 10px 90px;
    padding: 20px;
    transition: all 0.5s;
    background: #fff;
    border-radius: 10px;
    min-height: 85vh;
}

.sidebar{
    background: #ffffff;
    color: #fff;
    height: 100vh;
    width: 80px;
    transition: all 0.5s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
}

.sidebar:hover{
    width: 250px;
}

.top_section{
    display: flex;
    align-items:center;
    padding: 15px 10px;
    cursor: pointer;
    height: 85px;
    margin-bottom: 20px;
}

.logo{
    font-size: 30px;
}

.logo-wp{
    width: 55px;
}

.logo-text{
    width: 140px;
}

.sidebar.isClosed .top_section .logo{
    display: none;
}

.bars{
    display: flex;
    font-size: 25px;
}
.link{
    display: flex;
    color: #752367;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    justify-content: start;
    text-decoration: none;
    align-items: center;
}

.sidebar.isClosed .link{
    justify-content: center;
}

.sidebar.isClosed:hover .link{
    justify-content: start;
}

.sidebar.isClosed .link .link_text{
    display: none;
}

.sidebar.isClosed:hover .link .link_text{
    display: block;
}

.sidebar.isClosed .link .link_text:hover{
    display: block;
}

/* .headerContainer.isClosed{
    left: 15%;
} */

main .bodyContainer.isClosed{
    margin-left: 10px; 
}

.link:hover{
    background:#ebebeb;
    transition: all 0.5s;
}
.active{
    background: #e2bddb;
    color: #fff;
    width: 100%;
}

.active .icon svg > path{
    fill: #fff;
}

.icon, .link_text{
    font-size: 1.1rem;
}

.link_text_wizard{
    font-size: .8rem;
}

@media (max-width: 1200px){
    main .bodyContainer{
        margin: 95px 10px 10px 10px;
    }

    .logo-text {
        width: 130px;
    }
    .logo-wp {
        width: 47px;
    }

    .sidebar{
        width: 250px;
    }

    .sidebar.isClosed{
        display: none;
    }
}

@media (max-width: 1024px){
    .top_section{
        justify-content: center;
    }

    .logo-wp {
        width: 40px;
    }

    .logo-text {
        width: 110px;
        padding: 0 0 5px;
    }
}

@media (max-width: 860px) {

    main .bodyContainer{
        margin-left: 10px;
    }

    /* .sidebar.isClosed {
        width: 10%;
    } */

    main .bodyContainer.isClosed {
        margin-left: 10px;
    }

    .sidebar{
        transition: 1s 0.8s ease-in-out;
        box-shadow: 3px 1px 1px #0000004d, 0 1px 6px 5px #00000026;
    }
}

@media (max-width: 540px){

    .logo-wp{
        width: 35px;
    }

    .logo-text {
        width: 91px;
    }

    .logo {
        font-size: 30px;
        padding: 0 0 10px;
    }

    .link_text {
        font-size: 0.9rem;
    }
}